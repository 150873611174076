/*
 * @Author: 涵崽
 * @Date: 2024-08-20 13:44:51
 * @email: 1045654@qq.com
 * @gitee: https://gitee.com/han-zai
 * @LastEditors: 涵崽
 * @LastEditTime: 2024-08-20 14:15:39
 * @Description: 微信：1045654
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
// npm i normalize.css --save-dev
import 'normalize.css/normalize.css' // 清除浏览器css差异
import VueTypedJs from 'vue-typed-js'
import '@/utils/resizeHandler'
Vue.use(VueTypedJs)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
