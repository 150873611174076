// 判断是pc 还是 移动端
export const isMobile = () => {
  const userAgentInfo = navigator.userAgent
  const mobileAgents = ['Android', 'iPhone']
  let flag = false
  for (let i = 0, len = mobileAgents.length; i < len; i++) {
    if (userAgentInfo.indexOf(mobileAgents[i]) > -1) {
      flag = true
      break
    }
  }
  return flag
}
// 获取当前时间
export const getCurrentTime = () => {
  const date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  return `${year}-${month}-${day}`
}
