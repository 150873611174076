/*
 * @Author: 涵崽
 * @Date: 2024-08-20 13:44:51
 * @email: 1045654@qq.com
 * @gitee: https://gitee.com/han-zai
 * @LastEditors: 涵崽
 * @LastEditTime: 2024-08-21 09:30:41
 * @Description: 微信：1045654
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { isMobile } from '@/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(`@/views/index/${getDeviceComponent()}.vue`)
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
function getDeviceComponent() {
  // 假设有一个isMobile函数可以返回'mobile'或'pc'
  const deviceType = isMobile()
  if (deviceType) {
    return `mobile`
  }
  return 'pc'
}
