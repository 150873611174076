/*
 * @Author: 涵崽
 * @Date: 2023-11-06 10:16:55
 * @email: 1045654@qq.com
 * @gitee: https://gitee.com/han-zai
 * @LastEditors: 涵崽
 * @LastEditTime: 2024-08-21 10:45:48
 * @Description: 自动监听用户当前窗口大小(每次执行方法前先清除定时器。一段时间后改变html字体大小，)
 *
 * 注意！： 此文件不能直接修改body的字体大小，否则会出现bug。比如给某个元素设置rem单位时不会实现响应式
 *
 * 此方法配合"px to rem"插件 效果更佳！！！
 *
 * 使用方法：在mian.js中引入
 * import './resizeHandler.js';
 *
 * pc 端默认宽高1920 * 1080
 * pc 默认字体大小10px
 *
 * 移动端默认宽高390 * 844
 *
 */
let resizeTimer // 定时器
import { isMobile } from '@/utils'
function resizeHandler() {
  clearTimeout(resizeTimer) // 清除之前的定时器

  resizeTimer = setTimeout(function () {
    let width = document.documentElement.clientWidth
    let height = document.documentElement.clientHeight
    let html = document.getElementsByTagName('html')[0]
    let fontSize
    if (isMobile()) {
      let designWidth = 390 // 设计稿的宽度
      let designHeight = 844 // 设计稿的高度
      let defaultFontSize = 10 // 当前默认字体大小

      fontSize = Math.min((width / designWidth) * defaultFontSize)
    } else {
      let designWidth = 1920 // 设计稿的宽度
      let designHeight = 1080 // 设计稿的高度
      let defaultFontSize = 10 // 当前默认字体大小

      fontSize = Math.min(
        (width / designWidth) * defaultFontSize,
        (height / designHeight) * defaultFontSize
      )
    }

    html.style.fontSize = fontSize + 'px'
  }, 4) // 延迟执行代码的时间（毫秒）
}

window.addEventListener('resize', resizeHandler)
resizeHandler()
